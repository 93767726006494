<template>
  <div class="mx-a mb-16">
    <div class="category-wrap">
      <div class="category-content-wrap">
        <div class="sort-div">
          <div class="d-flex">
            <select-box
              :opt-nm="SELT_ORD"
              v-model="params.order"
              style="width: 120px; margin-right: 16px; text-align: left"
            />
            <select-box
              :opt-nm="SELT_CNT"
              v-model="params.numOfData"
              style="width: 120px; margin-right: 16px; text-align: left"
            />
          </div>
        </div>
        <div
          class="notice-wrap"
          v-for="notice in noticeList"
          :key="notice.esntlId"
          @click="goNoticeDetail(notice)"
        >
          <div class="flex-align-center">
            <div
              class="notice-accent"
              style="margin-right: 13px"
              v-if="notice.top === 'true'"
            >
              중요
            </div>
            <span class="notice-title" style="margin-right: 5px" v-else
              >[일반]</span
            >
            <span class="notice-title">{{ notice.title }}</span>
          </div>
          <span class="notice-title">{{ formatDate(notice.eDate) }}</span>
        </div>
        <pagination class="mt-16" @click="onChangePage" />
        <div class="flex-justify-center w100">
          <input-text
            style="width: 250px; margin-right: 16px"
            v-model="params.searchString"
          />
          <button-common :size="SIZE_T" :clr="CLR_G" @click="getData"
            >검색</button-common
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "NoticeList",
  mixins: [],
  components: {},

  /*** data ***/
  data() {
    return {
      params: {
        page: 1,
        order: "DESC",
        searchString: "",
        numOfData: 10,
      },
      noticeList: [],
      totCnt: 0,
    };
  },

  /*** created ***/
  /*** created hook ***/
  created() {},

  /*** mounted ***/
  async mounted() {
    await this.getData();
  },

  /*** computed ***/
  computed: {
    ...mapGetters([
      "G_PAGE",
      "G_ORDER",
      "G_LSTCNT",
      "G_FOLDER",
      "G_FOLDER_ID",
      "G_FOLDER_ARR",
      "G_MODAL_TOGGLE",
    ]),
  },

  /*** watch ***/
  watch: {
    async G_MODAL_TOGGLE(val) {
      // 열림
      if (!val) {
        this.getData();
      }
    },
  },

  /*** methods ***/
  methods: {
    // 데이터 가져오기 공통모듈
    async getData() {
      const rs = await this.$axios({
        url: "/partner/notice",
        params: this.params,
      });
      if (rs.result === "SUC") {
        this.noticeList = rs.resultList;
        this.noticeList.sort((a, b) => {
          return a.top;
        });
        await this.$store.dispatch("A_PAGE", rs.page);
        await this.$store.dispatch("A_TOTCNT", rs.totcnt);
      }
    },
    async onChangePage(value) {
      this.params = { ...this.params, page: value };
      this.pager = { ...this.pager, page: value };
      await this.getData();
    },
    formatDate(date) {
      return this.$moment(date).format("YYYY.MM.DD");
    },
    goNoticeDetail(row) {
      this.$router.push({
        path: `/notice/detail/` + row.esntlId,
        meta: { id: "5-1-1-1" },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.category-wrap {
  width: 100%;
  background: #fff;
  margin-top: 10px;
  border: 1px solid #d8dae5;
}
.category-header-wrap {
  width: 100%;
  border-bottom: 1px solid #d8dae5;
  padding: 16px 32px;
  font-size: 16px;
  font-weight: 500;
  color: #515466;
}
.category-content-wrap {
  padding: 16px 0 48px 0;
}
.sort-div {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #d8dae5;
}
.sort-count {
  font-size: 16px;
  font-weight: 500;
  color: #515466;
}
.notice-wrap {
  height: 56px;
  border-bottom: 1px solid #d8dae5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  cursor: pointer;
}
.notice-accent {
  color: #f1431d;
  border: 1px solid #f1431d;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 700;
}
.notice-title {
  color: #515466;
  font-weight: 500;
  font-size: 14px;
}
.button-div {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 16px;
}
</style>
